import React, { Component } from 'react';
import { ClimbingBoxLoader } from 'react-spinners'

// import axios from 'axios';
import PropTypes from 'prop-types';

class RentACar extends Component {

  static contextTypes = {
    router: PropTypes.object
  }

  constructor() {
    super()

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      startDate: '',
      endDate: '',
      carType: 'Economic',
      firstAddress: '',
      lastAddress: '',
      driveBh: '',
      message: '',
      phoneNumber: null,
      showNameError: false,
      showEmailError: false,
      showDateError: false,
      showAddressError: false,
      scrollTop: 0,
      showSuccessMessage: false,
      showFailureMessage: false,
      showLoader: false

    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.scroll = React.createRef();
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleSubmit(e) {
    e.preventDefault()

    const { firstName, lastName, email, startDate, endDate, firstAddress, lastAddress, carType, driveBh, message, phoneNumber } = this.state;

    if (firstName.trim().length < 1 || lastName.trim().length < 1) {
      this.setState({
        showNameError: true,
        showEmailError: false,
        showDateError: false,
        showAddressError: false
      })
      this.scroll.current.scrollTop = this.state.scrollTop;
    } else if (email.trim().length < 1) {
      this.setState({
        showNameError: false,
        showEmailError: true,
        showDateError: false,
        showAddressError: false
      })
    } else if (!startDate || !endDate) {
      this.setState({
        showNameError: false,
        showEmailError: false,
        showDateError: true,
        showAddressError: false
      })
    } else if (firstAddress.trim().length < 1 || lastAddress.trim().length < 1) {
      this.setState({
        showNameError: false,
        showEmailError: false,
        showDateError: false,
        showAddressError: true
      })
    } else {

      this.setState({
        showNameError: false,
        showEmailError: false,
        showDateError: false,
        showAddressError: false
      })

      const templateId = 'template_1f9ezos';
      this.sendEmail(templateId, { firstName, lastName, email, date: `${startDate} - ${endDate}`, passangers: carType, firstAddress, lastAddress, roundTrip: driveBh, message, phoneNumber, type: 'RENT A CAR' })
      // await this.sendRequest();
      // await this.redirect();

    }
  }

  sendEmail(templateId, variables) {
    this.setState({ showLoader: true })
    window.emailjs.send('service_q3cdma8', templateId, variables, 'user_wUiv2JBaQkOPoP3f91WaK')
      .then((res) => {
        this.setState({ showSuccessMessage: true, showLoader: false })
      })
      .catch(err => this.setState({ showFailureMessage: true, showLoader: false })
      )

  }

  showErrorText = () => {
    if (this.state.showNameError) {
      return (
        <p className="errorMsg">Name is required!</p>
      );
    } if (this.state.showEmailError) {
      return (
        <p className="errorMsg">Email address is required!</p>
      );
    } if (this.state.showDateError) {
      return (
        <p className="errorMsg">Date is required!</p>
      );
    } if (this.state.showAddressError) {
      return (
        <p className="errorMsg">Address is required!</p>
      );
    }
  }

  renderSuccessPopup = () => {
    const { showFailureMessage, showSuccessMessage } = this.state;
    if (showSuccessMessage) {
      return (
        <div className="detailsContainer">
          <div className="detailsPopup">
            <p className="successHeader">Success</p>
            <p className="successText">Your message has been sent. We will contact you soon.</p>
            <button className="successButton" onClick={() => this.onClosePopup('success')}>OK</button>
          </div>
          <div className="ui-widget-overlay" />
        </div>
      );
    } else if (showFailureMessage) {
      return (
        <div className="detailsContainer">
          <div className="detailsPopup">
            <p className="failureHeader">Failure</p>
            <p className="successText">Error while trying to send message, please try again or contact us directly on phone or email.</p>
            <button className="failureButton" onClick={() => this.onClosePopup('failure')}>OK</button>
          </div>
          <div className="ui-widget-overlay" />
        </div>
      );
    } else {
      return null;
    }
  }

  onClosePopup = (value) => {
    this.setState({
      showFailureMessage: false,
      showSuccessMessage: false
    })
    if (value === 'success') {
      this.context.router.history.push(`/`);
    }
  }

  // sendRequest = () => {
  //   const { firstName, lastName, email, startDate, endDate, carType, firstAddress, lastAddress, driveBh, message, phoneNumber } = this.state;
  //   axios.post('rent/form', {
  //     firstName,
  //     lastName,
  //     email,
  //     startDate,
  //     endDate,
  //     carType,
  //     firstAddress,
  //     lastAddress,
  //     driveBh,
  //     message,
  //     phoneNumber
  //   })
  // }

  // redirect = () => {
  //   this.context.router.history.push(`/success`);
  // }

  render() {
    return this.state.showLoader ? (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <ClimbingBoxLoader
          color='#5967c3' size={20} loading={this.state.showLoader} />
      </div>
    ) : (
      <div className="rent">
        {this.renderSuccessPopup()}
        <div className="text-rent" >

          <h3>Rent A Car</h3>
          <p><strong>We offer you best deal for rent a car in Sarajevo for all types of vehicles.</strong></p>

        </div>
        <form onSubmit={this.handleSubmit} ref={this.scroll}>
          <div className="rentName">
            <div className="rentFirstName">
              <label for="firstName">First Name</label>
              <input type="text" className="form-control" name="firstName" maxLength="20"
                placeholder="First name" onChange={this.handleChange} />
            </div>
            <div className="rentLastName">
              <label for="lastName">Last Name</label>
              <input type="text" className="form-control" name="lastName" maxLength="20"
                placeholder="Last name" onChange={this.handleChange} />
            </div>
          </div>
          {this.state.showNameError && (
            <div className="errorMsg">
              <p>Name is reqiured</p>
            </div>
          )}
          <div className="form-group rentInputs">
            <label for="InputEmail">Email address</label>
            <input type="email" className="form-control" id="InputEmail" maxLength="30"
              name="email" aria-describedby="emailHelp" onChange={this.handleChange} placeholder="Enter email" />
            {this.state.showEmailError && (
              <div className="errorMsg">
                <p>Email is reqiured</p>
              </div>
            )}
          </div>
          <div className="row rentInputs">
            <div className="col-sm">
              <label for="dateFrom">Start Date</label>
              <input type="date" className="form-control" name="startDate"
                onChange={this.handleChange} id="dateFrom" />
            </div>
            <div className="col-sm">
              <label for="dateTo">End Date</label>
              <input type="date" className="form-control" name="endDate"
                onChange={this.handleChange} id="dateTo" />
            </div>
          </div>
          {this.state.showDateError && (
            <div className="errorMsg">
              <p>Date is reqiured</p>
            </div>
          )}
          <div className="form-group rentInputs">
            <label for="FormSelect">Type of car</label>
            <select className="form-control" name="carType" onChange={this.handleChange} id="FormSelect">
              <option>Economic</option>
              <option>Compact</option>
              <option>Limousine</option>
              <option>Luxury</option>
              <option>Van</option>
            </select>
          </div>
          <div className="form-group rentInputs">
            <label for="PickUp">Pick Up Address</label>
            <input type="text" className="form-control" name="firstAddress" onChange={this.handleChange} id="PickUp" maxLength="50" placeholder="Pick Up Address" />
          </div>
          {this.state.showAddressError && (
            <div className="errorMsg">
              <p>Address is reqiured</p>
            </div>
          )}
          <div className="form-group rentInputs">
            <label for="DropOff">Drop Off Address</label>
            <input type="text" className="form-control" id="DropOff" name="lastAddress" maxLength="50"
              onChange={this.handleChange} placeholder="Your Destination" />
          </div>
          <div className="form-group rentInputs">
            <label for="driveBh">Will you use car outside of B&H?</label>
            <select className="form-control" name="driveBh" onChange={this.handleChange} id="driveBh">
              <option>Yes</option>
              <option>No</option>
            </select>
          </div>
          <div className="form-group rentInputs">
            <label for="phoneNumber">Phone Number</label>
            <input type="number" className="form-control" id="phoneNumber" name="phoneNumber"
              onChange={this.handleChange} placeholder="Your phone number" />
          </div>
          <div className="form-group rentInputs">
            <label for="Textarea1">Message:</label>
            <textarea className="form-control" maxLength="300" name="message" id="Textarea1" rows="4" onChange={this.handleChange}></textarea>
          </div>
          <div className="form-group rentInputs btnErrorMsg">
            <button type="submit" className="btn btn-primary purpleButton">Submit</button>
            {this.showErrorText()}
          </div>
        </form>
      </div>
    );
  }
}

export default RentACar;