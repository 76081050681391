import React from 'react';
import { Link } from 'react-router-dom';

let mostar = [
  { "name": "- Leaving Sarajevo in morning" },
  { "name": "- Break in Konjic (sightseeing)" },
  { "name": "- Arriving in Mostar (sightseeing)" },
  { "name": "- Visiting Blagaj (Dervish's House and Buna Spring)" },
  { "name": "- Back to Sarajevo" },
];

let olypmicTour = [
  { "name": "- Ride from Sarajevo to Igman" },
  { "name": "- Igman sightseeing" },
  { "name": "- Bjelasnica sightseeing" },
  { "name": "- Trebevic sightseeing (best view of Sarajevo)" },
  { "name": "- Back to Sarajevo" },

];

let bihac = [
  { "name": "- Start from Sarajevo in morning" },
  { "name": "- Break in Travnik and sightseeing" },
  { "name": "- Visiting Jajce and sightseeing" },
  { "name": "- Arriving in Bihac and sleep in accommodation" },
  { "name": "- Second Day: Visiting Strbacki Buk" },
  { "name": "- Visiting Martin Brod Waterfalls" },
  { "name": "- Back to Sarajevo" }
];

let travnik = [
  { "name": "- Start from Sarajevo in morning" },
  { "name": "- Travnik sightseeing" },
  { "name": "- Jajce sightseeing" },
  { "name": "- Pivsko Lake sightseeing" },
  { "name": "- Back to Sarajevo" }
];

let vlasic = [
  { "name": "- Start from Sarajevo in morning" },
  { "name": "- Travnik sightseeing" },
  { "name": "- Visiting Vlasic" },
  { "name": "- Back to Sarajevo" }
];

let bijambare = [
  { "name": "- Start from Sarajevo in morning" },
  { "name": "- Bijambare sightseeing" },
  { "name": "- Trebevic sightseeing" },
  { "name": "- Back to Sarajevo" }
];

let mostarTwoDays = [
  { "name": "- Start from Sarajevo in morning" },
  { "name": "- Konjic sightseeing" },
  { "name": "- Mostar sightseeing and night in Mostar" },
  { "name": "- Visiting Kravice" },
  { "name": "- Visiting Blagaj" },
  { "name": "- Back to Sarajevo" },
];

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mostar: mostar,
      bihac: bihac,
      travnik: travnik,
      bookedTour: ''
    }

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = (e) => {
    e.preventDefault();

    this.props.close(e)
  }


  render() {
    let linkUrl = {
      pathname: '/transfers',
      param1: this.props.data + " Special Offer"
    };
    let data = [];
    if (this.props.data === "Mostar") {
      data = this.state.mostar
    } if (this.props.data === "Olympic") {
      data = olypmicTour;
    } if (this.props.data === "Bihac") {
      data = this.state.bihac
    } if (this.props.data === "Travnik") {
      data = this.state.travnik
    } if (this.props.data === "Vlasic") {
      data = vlasic;
    } if (this.props.data === "Bijambare") {
      data = bijambare;
    } if (this.props.data === "Mostar2Days") {
      data = mostarTwoDays;
    }

    return (
      <div className="detailsPopup">
        <div className="detailsHeader">
          <p>{this.props.data}{" "} Tour</p>
        </div>
        <div className="detailsMain">
          <div className="detailsDetails">
            <ul>
              {data.map(item => {
                return (
                  <li>{item.name}</li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="detailsBtns">
          <button
            className="btn btn-primary"
            onClick={(e) => this.handleClose(e)}
          >
            Close
          </button>
          <button
            className="btn btn-primary"

          ><Link to={linkUrl}> Book Tour </Link>
          </button>
        </div>
      </div>
    );
  }
}

export default Details;