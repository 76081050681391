import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Header from './components/Header';

// import Main from './components/Main';
import Home from './components/new-design/Home.js'
import Footer from './components/Footer';
import Shuttle from './components/Shuttle';
import RentACar from './components/RentACar';
import Transfers from './components/Transfers';
import Contacts from './components/Contacts';
import SuccessComponent from './components/SuccessComponent';
import SearchResults from './components/new-design/SearchResults';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Header />
          {/* <Route exact={true} path="/" component={Main} /> */}
          <Route exact={true} path="/" component={Home} />
          <Route exact path="/searchTransfer" component={SearchResults} />

          <Route exact path="/airport" component={Shuttle} />
          <Route exact path="/rent" component={RentACar} />
          <Route exact path="/transfers" component={Transfers} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/success" component={SuccessComponent} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;