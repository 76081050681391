import React, { Component } from 'react';
import { ClimbingBoxLoader } from 'react-spinners'
// import axios from 'axios';
import PropTypes from 'prop-types'
import Viber from '../img/Viber.png';
import Whatsapp from '../img/Whatsapp.png'

class Contacts extends Component {

  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      phoneNumber: null,
      showFirstNameError: false,
      showLastNameError: false,
      emailError: false,
      showMessageError: false,
      showSuccessMessage: false,
      showFailureMessage: false,
      showLoader: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.sendRequest = this.sendRequest.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { firstName, lastName, email, message, phoneNumber } = this.state;


    if (firstName.trim().length < 1) {
      this.setState({
        showFirstNameError: true,
        showLastNameError: false,
        emailError: false
      })
    } else if (lastName.trim().length < 1) {
      this.setState({
        showLastNameError: true,
        showFirstNameError: false,
        emailError: false
      })
    } else if (email.trim().length < 1) {
      this.setState({
        emailError: true,
        showFirstNameError: false,
        showLastNameError: false,
      })
    } else if (this.state.message.trim().length < 1) {
      this.setState({
        emailError: false,
        showFirstNameError: false,
        showLastNameError: false,
        showMessageError: true
      })
    } else {
      this.setState({
        showFirstNameError: false,
        showLastNameError: false,
        emailError: false,
        showMessageError: false
      })

      // await this.sendRequest();
      const templateId = 'template_m3qae7k';
      this.sendEmail(templateId, { message, firstName, lastName, email, phoneNumber, type: 'Contact Message' })
    }
  }

  sendEmail(templateId, variables) {
    this.setState({ showLoader: true })

    window.emailjs.send('service_q3cdma8', templateId, variables, 'user_wUiv2JBaQkOPoP3f91WaK')
      .then((res) => {
        this.setState({ showSuccessMessage: true, showLoader: false })
      })
      .catch(err => {
        this.setState({ showFailureMessage: true, showLoader: false })
      })
  }

  renderSuccessPopup = () => {
    const { showFailureMessage, showSuccessMessage } = this.state;
    if (showSuccessMessage) {
      return (
        <div className="detailsContainer">
          <div className="detailsPopup">
            <p className="successHeader">Success</p>
            <p className="successText">Your message has been sent. We will contact you soon.</p>
            <button className="successButton" onClick={() => this.onClosePopup('success')}>OK</button>
          </div>
          <div className="ui-widget-overlay" />
        </div>
      );
    } else if (showFailureMessage) {
      return (
        <div className="detailsContainer">
          <div className="detailsPopup">
            <p className="failureHeader">Failure</p>
            <p className="successText">Error while trying to send message, please try again or contact us directly on phone or email.</p>
            <button className="failureButton" onClick={() => this.onClosePopup('failure')}>OK</button>
          </div>
          <div className="ui-widget-overlay" />
        </div>
      );
    } else {
      return null;
    }
  }

  onClosePopup = (value) => {
    this.setState({
      showFailureMessage: false,
      showSuccessMessage: false
    })
    if (value === 'success') {
      this.context.router.history.push(`/`);
    }
  }

  // sendRequest = () => {
  //   const { firstName, lastName, email, message, phoneNumber } = this.state;

  //   axios.post('contacts/form', {
  //     firstName,
  //     lastName,
  //     email,
  //     message,
  //     phoneNumber
  //   });

  // }

  render() {
    return this.state.showLoader ? (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <ClimbingBoxLoader
          color='#5967c3' size={20} loading={this.state.showLoader} />
      </div>
    ) : (
      <div className="contacts">
        {this.renderSuccessPopup()}
        <div className="contactsText">
          <p className="contactsHeader">GET IN TOUCH</p>
          <div className="contactsMain">
            <p>We're social and we'd love to hear from you! Feel free to send us an email, message or call us.</p>
            {/* <div className='iconsHolder'>
              <img src={Viber} alt="viber" /> <img alt="whatsapp" src={Whatsapp} />
            </div>
            <a href="tel:+387603180226"><i className="fa fa-phone" aria-hidden="true"></i> +387 60 31 80 226 </a>
            <p><i className="fa fa-envelope" aria-hidden="true"></i> anas@wwtagency.com</p> */}
          </div>
        </div>
        <form onSubmit={this.handleSubmit}>

          <div className="contactsNames">
            <div className="contactsFirstName">
              <label for="firstName">First Name</label>
              <input type="text" name="firstName" className="form-control"
                placeholder="First Name" maxLength='20' onChange={this.handleChange} />
              {this.state.showFirstNameError &&
                (
                  <div className="errorMsg">
                    <p>First Name is reqiured</p>
                  </div>
                )
              }
            </div>
            <div className="contactsLastName">
              <label for="lastName">Last Name</label>
              <input type="text" name="lastName" className="form-control"
                placeholder="Last Name" maxLength='20' onChange={this.handleChange} />
              {this.state.showLastNameError &&
                (
                  <div className="errorMsg">
                    <p>Last Name is reqiured</p>
                  </div>
                )
              }
            </div>
          </div>
          <div className="form-group contactsInput">
            <label for="InputEmail">Email address</label>
            <input type="email" name="email" className="form-control"
              id="InputEmail" maxLength='30' aria-describedby="emailHelp" placeholder="Enter email"
              onChange={this.handleChange} />
            {this.state.emailError &&
              (
                <div className="errorMsg">
                  <p>Email is reqiured</p>
                </div>
              )
            }
          </div>
          <div className="form-group contactsInput">
            <label for="phoneNumber">Phone Number</label>
            <input type="number" className="form-control" id="phoneNumber" name="phoneNumber"
              onChange={this.handleChange} maxLength='20' placeholder="Your phone number" />
          </div>
          <div className="form-group contactsInput">
            <label for="Textarea1">Message:</label>
            <textarea className="form-control" maxLength='300' name="message" id="Textarea1" rows="4" onChange={this.handleChange}></textarea>
            {this.state.showMessageError && (
              <div className="errorMsg">
                <p>Message is reqiured</p>
              </div>
            )}
          </div>
          <div className="form-group contactsInput">
            <button type="submit" className="btn btn-primary purpleButton">Submit</button>
          </div>
        </form>
      </div>
    );
  }
}

export default Contacts;