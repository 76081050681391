import React, { Component } from 'react';
import { ClimbingBoxLoader } from 'react-spinners'
// import axios from 'axios';
import PropTypes from 'prop-types';

class Transfers extends Component {

  static contextTypes = {
    router: PropTypes.object
  }

  constructor() {
    super()

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      date: '',
      passangers: '',
      firstAddress: '',
      lastAddress: '',
      roundTrip: '',
      message: '',
      phoneNumber: null,
      showFailureMessage: false,
      showSuccessMessage: false,
      showNameError: false,
      showEmailError: false,
      showDateError: false,
      showAddressError: false,
      showLoader: false,
      notificationDOMRef: React.createRef()
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  async handleSubmit(e) {
    e.preventDefault()

    if (this.props.location.param1) {
      this.setState({
        lastAddress: this.props.location.param1
      });
    }

    const { firstName, lastName, email, date, firstAddress, lastAddress, passangers, roundTrip, message, phoneNumber } = this.state;

    if (firstName.trim().length < 1 || lastName.trim().length < 1) {
      this.setState({
        showNameError: true,
        showEmailError: false,
        showDateError: false,
        showAddressError: false
      })
    } else if (email.trim().length < 1) {
      this.setState({
        showNameError: false,
        showEmailError: true,
        showDateError: false,
        showAddressError: false
      })
    } else if (!date) {
      this.setState({
        showNameError: false,
        showEmailError: false,
        showDateError: true,
        showAddressError: false
      })
    } else if (firstAddress.trim().length < 1 || lastAddress.trim().length < 1) {
      this.setState({
        showNameError: false,
        showEmailError: false,
        showDateError: false,
        showAddressError: true
      })
    } else {

      this.setState({
        showNameError: false,
        showEmailError: false,
        showDateError: false,
        showAddressError: false
      })

      const templateId = 'template_1f9ezos';
      this.sendEmail(templateId, { firstName, lastName, email, date, passangers, firstAddress, lastAddress, roundTrip, message, phoneNumber, type: 'Transfers' })

      // await this.sendRequest();
      // await this.redirect();

    }
  }

  sendEmail(templateId, variables) {
    this.setState({ showLoader: true })

    window.emailjs.send('service_q3cdma8', templateId, variables, 'user_wUiv2JBaQkOPoP3f91WaK')
      .then((res) => {
        this.setState({ showSuccessMessage: true, showLoader: false });
      })
      .catch(err => this.setState({ showFailureMessage: true, showLoader: false }))
  }

  showErrorText = () => {
    if (this.state.showNameError) {
      return (
        <p className="errorMsg">Name is required!</p>
      );
    } if (this.state.showEmailError) {
      return (
        <p className="errorMsg">Email address is required!</p>
      );
    } if (this.state.showDateError) {
      return (
        <p className="errorMsg">Date is required!</p>
      );
    } if (this.state.showAddressError) {
      return (
        <p className="errorMsg">Address is required!</p>
      );
    }
  }

  renderSuccessPopup = () => {
    const { showFailureMessage, showSuccessMessage } = this.state;
    if (showSuccessMessage) {
      return (
        <div className="detailsContainer">
          <div className="detailsPopup">
            <p className="successHeader">Success</p>
            <p className="successText">Your message has been sent. We will contact you soon.</p>
            <button className="successButton" onClick={() => this.onClosePopup('success')}>OK</button>
          </div>
          <div className="ui-widget-overlay" />
        </div>
      );
    } else if (showFailureMessage) {
      return (
        <div className="detailsContainer">
          <div className="detailsPopup">
            <p className="failureHeader">Failure</p>
            <p className="successText">Error while trying to send message, please try again or contact us directly on phone or email.</p>
            <button className="failureButton" onClick={() => this.onClosePopup('failure')}>OK</button>
          </div>
          <div className="ui-widget-overlay" />
        </div>
      );
    } else {
      return null;
    }
  }

  onClosePopup = (value) => {
    this.setState({
      showFailureMessage: false,
      showSuccessMessage: false
    })
    if (value === 'success') {
      this.context.router.history.push(`/`);
    }
  }

  // sendRequest = () => {
  //   if (this.props.location.param1) {
  //     this.setState({
  //       lastAddress: this.props.location.param1
  //     });
  //   }
  //   const { firstName, lastName, email, date, passangers, firstAddress, lastAddress, roundTrip, message, phoneNumber } = this.state;

  //   axios.post("shuttle/form", {
  //     firstName,
  //     lastName,
  //     email,
  //     date,
  //     passangers,
  //     firstAddress,
  //     lastAddress,
  //     roundTrip,
  //     message,
  //     phoneNumber
  //   })
  // }

  // redirect = () => {
  //   this.context.router.history.push(`/success`);
  // }

  render() {
    // console.log('sddsd', this.props.location.state.params)
    return this.state.showLoader ? (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <ClimbingBoxLoader
          color='#5967c3' size={20} loading={this.state.showLoader} />
      </div>
    ) : (
      <div className="transfers">
        {this.renderSuccessPopup()}
        <div className="text-transfer">
          <h3>Transfers to any destination in Bosnia and Herzegovina</h3>
          <p><strong>We can help you with safe and comfortable transfer to any destination.</strong></p>
        </div>
        <form className="transfersForm" onSubmit={this.handleSubmit}>
          <div className="transfersName">
            <div className="transfersFirstName">
              <label for="firstName">First Name</label>
              <input type="text" className="form-control" name="firstName"
                placeholder="First name" maxLength="20" onChange={this.handleChange} />
            </div>
            <div className="transfersLastName">
              <label for="lastName">Last Name</label>
              <input type="text" className="form-control" name="lastName"
                placeholder="Last name" maxLength='20' onChange={this.handleChange} />
            </div>
          </div>
          {this.state.showNameError && (
            <div className="errorMsg">
              <p>Name is reqiured</p>
            </div>
          )}
          <div className="form-group transfersInput">
            <label for="InputEmail">Email address</label>
            <input type="email" maxLength='30' className="form-control" id="InputEmail"
              name="email" aria-describedby="emailHelp" placeholder="Enter email" onChange={this.handleChange} />
            {this.state.showEmailError && (
              <div className="errorMsg">
                <p>Email is reqiured</p>
              </div>
            )}
          </div>
          <div className="form-group transfersInput">
            <label for="inputDate">Date of travel</label>
            <input type="date" className="form-control" name="date"
              onChange={this.handleChange} id="inputDate" />
            {this.state.showDateError && (
              <div className="errorMsg">
                <p>Date is reqiured</p>
              </div>
            )}
          </div>
          <div className="form-group transfersInput">
            <label for="FormSelect">Number of passangers</label>
            <select className="form-control" name="passangers" id="FormSelect" onChange={this.handleChange}>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
            </select>
          </div>
          <div className="form-group transfersInput">
            <label for="PickUp">Pick Up Address</label>
            <input type="text" className="form-control" id="PickUp" maxLength='50'
              name="firstAddress" placeholder="Pick Up Address" onChange={this.handleChange} />
          </div>
          {this.state.showAddressError && (
            <div className="errorMsg">
              <p>Address is reqiured</p>
            </div>
          )}
          <div className="form-group transfersInput">
            <label for="DropOff">Your Destination</label>
            <input type="text" className="form-control" id="DropOff" name="lastAddress" maxLength='50'
              placeholder="Your Destination" onChange={this.handleChange} value={this.props.location.state ? this.props.location.state.params : this.state.lastAddress} />
          </div>
          <div className="form-group transfersInput">
            <label for="Select">Round trip:</label>
            <select className="form-control" name="roundTrip" id="Select" onChange={this.handleChange}>
              <option>No</option>
              <option>Yes</option>
            </select>
          </div>
          <div className="form-group transfersInput">
            <label for="phoneNumber">Phone Number</label>
            <input type="number" className="form-control" id="phoneNumber" name="phoneNumber"
              onChange={this.handleChange} placeholder="Your phone number" />
          </div>
          <div className="form-group transfersInput">
            <label for="Textarea1">Message:</label>
            <textarea className="form-control" name="message" id="Textarea1" maxLength='300' rows="4" onChange={this.handleChange}></textarea>
          </div>
          <div className="form-group transfersInput btnErrorMsg">
            <button type="submit" className="btn btn-primary purpleButton">Submit</button>
            {this.showErrorText()}
          </div>
        </form>
      </div>
    );
  }
}

export default Transfers;